
import Vue from 'vue'

export default Vue.extend({
    name: 'Icon',
    props: {
        name: { type: String, required: true }
    },
    computed: {
        iconEl() {
            /* eslint-disable import/no-dynamic-require */
            /* eslint-disable global-require */
            /* eslint-disable @typescript-eslint/no-var-requires */

            const icon = require(`@/assets/svg-icons/${this.name}.svg`)
            return icon.default ?? icon
        }
    }
})
