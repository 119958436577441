/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import merge from '@/utils/merge'
import {
    BackSide,
    Mesh,
    MeshBasicMaterial,
    SphereGeometry,
    TextureLoader
} from 'three'
import type ARScene from '../../core'
import { OnProgress, OnTick } from '../../core/types'
import { DEF_SPHERE_LAYER_PARAMS } from './const'
import {
    ResourceConfig,
    Resource,
    GameObject,
    SphereLayerParams
} from './types'

class Base {
    protected hasCamLayer = false

    protected loaded = false

    protected core: ARScene

    protected res: Map<string, Resource> = new Map()

    protected gameObjects: Map<string, GameObject> = new Map()

    constructor(core: ARScene) {
        this.core = core
    }

    public isLoaded(): boolean {
        return this.loaded
    }

    /* eslint-disable class-methods-use-this */
    /* eslint-disable @typescript-eslint/no-empty-function */
    public async prepare(): Promise<void> {}

    public start(): void {
        this.core.e.on('tick', this.onTick)
    }

    protected async loadResources(resources: ResourceConfig[], onProgress?: OnProgress): Promise<void> {
        if (this.loaded) {
            return
        }

        let current = 0
        let total = resources.length

        /* eslint-disable arrow-body-style */
        let promises = resources.map((res) => {
            return new Promise((resolve) => {
                let loader = new TextureLoader()
                loader.load(res.url, (texture) => {
                    this.res.set(res.name, { ...res, texture })

                    current += 1
                    if (onProgress) {
                        onProgress(current / total)
                    }

                    resolve(undefined)
                })
            })
        })

        await Promise.allSettled(promises)
        this.loaded = true
    }

    protected addCamLayer(posZ: number): void {
        if (this.hasCamLayer) {
            return
        }

        let { gyro, camera } = this.core.permission.accessMap
        if (camera && gyro) {
            this.addCameraStream()
        } else {
            this.addSphereLayer('camFake', { radius: posZ, transparent: false })
        }

        this.hasCamLayer = true
    }

    protected addCameraStream(): void {
        if (this.hasCamLayer) {
            return
        }

        let stream = this.core.permission.mediaStream
        if (!stream) {
            return
        }

        let video = this.core.render.video
        video.srcObject = stream
        video.play()
        this.core.render.video.classList.add('-active')
    }

    protected addSphereLayer(name: string, params: SphereLayerParams): void {
        let geometry = new SphereGeometry(params.radius, 32, 32)
        let res = this.res.get(name)

        let opts = merge({}, DEF_SPHERE_LAYER_PARAMS, params)

        let alphaMap
        if (opts.transparent && opts.alphaMap) {
            alphaMap = this.res.get(opts.alphaMap)?.texture
        }

        let material = new MeshBasicMaterial({
            side: BackSide,
            map: res?.texture,
            opacity: opts.opacity,
            transparent: opts.transparent,
            alphaMap
        })

        let mesh = new Mesh(geometry, material)

        this.gameObjects.set(name, { mesh, anim: res?.anim })
        this.core.render.scene.add(mesh)
    }

    protected onTick: OnTick = (delta) => {
        /* eslint-disable no-param-reassign */
        this.gameObjects.forEach((obj) => {
            if (obj.anim?.rotY) {
                obj.mesh.rotation.y += delta * obj.anim.rotY
            }
        })
    }

    public destroy(): void {
        this.hasCamLayer = false
        this.res.clear()
        this.gameObjects.clear()
    }
}

export default Base
