import Vue from 'vue'
import VueMeta from 'vue-meta'
import * as baseComponents from '@/components/base'
import '@/assets/styles/index.scss'
import router from './router'
import store from './store'
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

Object
    .entries(baseComponents)
    .forEach(([key, value]) => {
        Vue.component(key, value)
    })

new Vue({
    router,
    store,
    render: (h) => h(App),
    metaInfo: () => ({
        titleTemplate: '%s — Газпром нефть'
    })
}).$mount('#app')
