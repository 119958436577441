
import Vue from 'vue'

interface Params {
    size: number,
    r: number,
    center: number,
    strokeWidth: number,
    dashMax: number
}

export default Vue.extend({
    name: 'Progress',
    props: {
        // def | big
        size: { type: String, default: null },
        progress: { type: Number, default: 0 },
        variant: { type: String, default: 'yellow' }
    },
    computed: {
        params(): Params {
            let size
            let strokeWidth

            switch (this.size) {
                default:
                    size = 48
                    strokeWidth = 4
                    break
            }

            const r = (size - strokeWidth) / 2
            return {
                r,
                size,
                strokeWidth,
                center: size / 2,
                dashMax: 2 * Math.PI * r
            }
        },
        progressValue(): number {
            return this.progress * this.params.dashMax
        }
    }
})
