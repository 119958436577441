import { isFunc, isSet } from '@/utils/extend-is'
import type ARScene from '../../core'
import { NAMESPACE } from './const'
import { AccessMap } from './types'

class Permission {
    private core: ARScene

    private accessIsGetted = false

    public accessMap: AccessMap = { gyro: false, camera: false }

    public mediaStream: MediaStream | null = null

    constructor(core: ARScene) {
        this.core = core
    }

    public async requestPermissions(): Promise<void> {
        if (this.accessIsGetted) {
            return
        }

        await this.requestDeviceOrientationPermission()
        await this.requestCameraPermission()

        this.accessIsGetted = true
    }

    public async requestDeviceOrientationPermission(): Promise<void> {
        let gyroIsAvailable = false
        if (isSet(window.DeviceOrientationEvent) && isFunc(window.DeviceOrientationEvent.requestPermission)) {
            try {
                let res = await window.DeviceOrientationEvent.requestPermission()
                if (res === 'granted') {
                    gyroIsAvailable = true
                }
            } catch (e) {
                console.error(`[${NAMESPACE}.requestDeviceOrientationPermission]`, e)
            }
        } else {
            let testByEvent = new Promise<boolean>((resolve) => {
                // Есть крайне неожиданная особенность, которая заключается в том,
                // что сразу после подписки на событие deviceorientation происходит
                // его emit с null-ёвыми параметрамию
                //
                // В связи с этим скипаем первое тестовое событие
                let counter = 0
                let callback = () => {
                    if (counter) {
                        resolve(true)
                    }

                    counter += 1
                }

                window.addEventListener('deviceorientation', callback)

                // deadline для тестового события
                // Изначально стояло значение в 30мс, но для android-а этого оказалось
                // недостаточно, поэтому пусть будет "соточка"
                setTimeout(() => {
                    window.removeEventListener('deviceorientation', callback)
                    resolve(false)
                }, 100)
            })

            let emited = await testByEvent
            if (emited) {
                gyroIsAvailable = true
            }
        }

        this.accessMap.gyro = gyroIsAvailable
    }

    public async requestCameraPermission(): Promise<void> {
        let cameraIsAvailable = false
        try {
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: {
                        exact: 'environment'
                    }
                }
            } as any)
            cameraIsAvailable = true
        } catch (e) {
            console.error(`[${NAMESPACE}.requestCameraPermission]`, e)
        }

        this.accessMap.camera = cameraIsAvailable
    }

    public destroy(): void {
        this.mediaStream = null
        this.accessIsGetted = false
    }
}

export default Permission
