import Vue from 'vue'
import Vuex from 'vuex'
import { RootState } from './types'
import { modules, plugins } from './modules'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
    state: {
        appIsLoaded: false,
        loadingProgress: 0
    },
    mutations: {
        setLoadedState(state, payload: boolean) {
            state.appIsLoaded = payload
            state.loadingProgress = (payload) ? 1 : 0
        },
        updateLoadingProgress(state, payload: number) {
            state.loadingProgress = payload
        }
    },
    actions: {},
    modules,
    plugins
})
