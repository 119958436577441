/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export function isSet(a: any): boolean {
    return typeof (a) !== 'undefined'
}

export function isBool(a: any): boolean {
    return typeof (a) === 'boolean'
}

export function isEmpty(a: any): boolean {
    return typeof (a) === 'undefined' || a === null
}

export function isFunc(a: any): boolean {
    return typeof (a) === 'function'
}

export function isStr(a: any): boolean {
    return typeof (a) === 'string'
}

export function isObj(a: any): boolean {
    return typeof (a) === 'object'
}

export function isArray(a: any): boolean {
    return Array.isArray(a)
}
