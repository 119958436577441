export const vertexShader = `
attribute float size;
attribute float alpha;
attribute float rotation;

varying float vAlpha;
varying float vRotation;

void main() {
    vAlpha = alpha;
    vRotation = rotation;

    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);

    gl_PointSize = size * (300.0 / length(mvPosition.xyz));
    gl_Position = projectionMatrix * mvPosition;
}
`

export const fragmentShader = `
uniform sampler2D pointTexture;

varying float vAlpha;
varying float vRotation;

void main() {
    float mid = 0.5;
    vec2 rotated = vec2(
        cos(vRotation) * (gl_PointCoord.x - mid) + sin(vRotation) * (gl_PointCoord.y - mid) + mid,
        cos(vRotation) * (gl_PointCoord.y - mid) - sin(vRotation) * (gl_PointCoord.x - mid) + mid
    );

    gl_FragColor = texture2D(pointTexture, rotated);
    gl_FragColor.a = gl_FragColor.a * vAlpha;
}
`
