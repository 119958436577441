import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import type ARScene from '../../core'

class Controls {
    private core: ARScene

    public orientationControls?: DeviceOrientationControls

    public orbitControls?: OrbitControls

    constructor(core: ARScene) {
        this.core = core
    }

    public initControlls():void {
        let hasAcessToGyro = this.core.permission.accessMap.gyro
        let camera = this.core.render.camera

        if (hasAcessToGyro) {
            this.orientationControls = new DeviceOrientationControls(camera)
        } else {
            let controls = new OrbitControls(camera, this.core.render.renderer.domElement)

            if (this.core.isDebug()) {
                controls.target.set(0, 0, 10)
            } else {
                controls.target.set(0, 0, 0.0001)
                controls.enableZoom = false
                controls.enablePan = false
            }

            controls.update()
            this.orbitControls = controls
        }
    }

    public tick(): void {
        if (this.orientationControls) {
            this.orientationControls.update()
        }
    }

    public destroy(): void {
        if (this.orbitControls) {
            this.orbitControls.dispose()
            delete this.orbitControls
        }

        if (this.orientationControls) {
            this.orientationControls.dispose()
            delete this.orientationControls
        }
    }
}

export default Controls
