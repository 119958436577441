import { isBool, isSet, isEmpty } from './extend-is'

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */

type Obj = Record<string, any>

function deepMerge<T1 extends Obj, T2 extends Obj>(target: T1, source: T2): T1 | T2 | T1 & T2 {
    /* eslint-disable no-param-reassign */

    if (isEmpty(source)) {
        return target
    }

    for (let key of Object.keys(source)) {
        if (source[key] instanceof Object) {
            if (isEmpty(target[key])) {
                target[key as keyof T1] = Array.isArray(source[key])
                    ? [] as any
                    : {}
            }

            target[key as keyof T1] = deepMerge(target[key], source[key])
        } else if (isSet(source[key]) || !isSet(target[key])) {
            target[key as keyof T1] = source[key]
        }
    }

    return target
}

function merge<T>(t: T): T
function merge<T1, T2>(t1: T1, t2: T2): T1 & T2
function merge<T1, T2, T3>(t1: T1, t2: T2, t3: T3): T1 & T2 & T3

function merge<T>(deep: boolean, t1: T): T
function merge<T1, T2>(deep: boolean, t1: T1, t2: T2): T1 & T2
function merge<T1, T2, T3>(deep: boolean, t1: T1, t2: T2, t3: T3): T1 & T2 & T3

function merge(flag: boolean, ...args: any[]): any
function merge(...args: any[]): any

function merge(...args: any[]): any {
    if (isBool(args[0])) {
        let target = args[1]

        for (let i = 2; i < args.length; i += 1) {
            target = deepMerge(target, args[i])
        }

        return target
    }

    let target = args[0]
    for (let i = 1; i < args.length; i += 1) {
        let source = args[i]

        if (isEmpty(source)) {
            continue
        }

        for (let key of Object.keys(source)) {
            if (isSet(source[key]) || !isSet(target[key])) {
                target[key] = source[key]
            }
        }
    }

    return target
    // return Object.assign(...arguments)
}

export default merge
