import { Clock } from 'three'
import { EventEmitter } from 'events'
import { DEBUG } from './const'
import { RenderParams } from '../modules/render/types'
import Render from '../modules/render'
import Controls from '../modules/controls'
import Permission from '../modules/permission'
import { ScenarioNames, ScenarioTypes } from '../scenario/types'
import * as Scenarios from '../scenario'
import { FrameRequestCallback, OnProgress } from './types'

class ARScene {
    public e = new EventEmitter()

    public render: Render

    public controls: Controls

    public permission: Permission

    public scenario: ScenarioTypes

    public clock = new Clock()

    constructor(name: ScenarioNames, renderParams: RenderParams) {
        this.render = new Render(this, renderParams)
        this.controls = new Controls(this)
        this.permission = new Permission(this)

        this.scenario = new Scenarios[name](this)
    }

    /* eslint-disable class-methods-use-this */
    public isDebug(): boolean {
        return DEBUG
    }

    public async prepareScenario(onProgress?: OnProgress): Promise<void> {
        await this.scenario.prepare(onProgress)
    }

    public async startScenario(): Promise<void> {
        await this.permission.requestPermissions()

        this.controls.initControlls()
        this.scenario.start()

        this.tick()
    }

    private tick: FrameRequestCallback = () => {
        requestAnimationFrame(this.tick)

        this.controls.tick()
        this.render.tick()

        this.e.emit('tick', this.clock.getDelta())

        this.render.render()
    }

    public destroy(): void {
        this.e.removeAllListeners()

        this.controls.destroy()
        this.render.destroy()
    }
}

export default ARScene
