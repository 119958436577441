import { ResourceConfig } from '../Base/types'

export const RESOURCES: ResourceConfig[] = [
    {
        name: 'camFake',
        url: '/img/moscow.jpg'
    },
    {
        name: 'overlay',
        url: '/img/mist_back.png'
    },
    {
        name: 'mist1',
        url: '/img/mist1.png',
        anim: {
            rotY: -0.06
        }
    },
    {
        name: 'mist2',
        url: '/img/mist2.png',
        anim: {
            rotY: 0.04
        }
    },
    {
        name: 'mist3',
        url: '/img/mist3.png',
        anim: {
            rotY: -0.03
        }
    }
]
