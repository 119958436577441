
import Vue from 'vue'
import Scene from '@/components/Scene.vue'
import gsap from 'gsap'
import { ARScene } from '@/modules/ARScene'
import { Smoke } from '@/modules/ARScene/scenario'

let tl: gsap.core.Timeline | undefined

export default Vue.extend({
    name: 'Index',
    components: {
        Scene
    },
    metaInfo() {
        return {
            title: 'Триггеры выбросов СО2 при эксплуатации офиса'
        }
    },
    data() {
        return {
            min: 0,
            max: 2000,
            current: 0,

            segmentCount: 13,
            markerPos: 0,
            progressHeight: 0,
            sources: [
                { anchor: 1.6, percent: 2, text: 'Наружное освещение' },
                { anchor: 10, percent: 7, text: 'Внутреннее освещение' },
                { anchor: 20.5, percent: 8, text: 'Вентиляция' },
                { anchor: 30, percent: 12, text: 'Кондиционирование' },
                { anchor: 77, percent: 71, text: 'Отопление' }
            ]
        }
    },
    watch: {
        current() {
            this.updatePos()
        }
    },
    methods: {
        async startAnimation(): Promise<void> {
            await this.$nextTick()

            this.updatePos()

            tl = gsap.timeline()
            tl.to(this, {
                current: this.max,
                duration: 5,
                delay: 2,
                ease: 'power1.inOut'
            })
            tl.to(this, {
                current: 0.85 * this.max,
                duration: 2,
                ease: 'power1.inOut',
                repeat: -1,
                yoyo: true
            })
        },
        updatePos(): void {
            let part = (this.current - this.min) / (this.max - this.min)
            part = (part >= 0) ? part : 0
            part = (part <= 1) ? part : 1

            let $track = this.$refs.track as Element

            this.markerPos = part * $track.clientHeight
            this.progressHeight = part * 100

            let sceneComponent = this.$refs.scene as any
            let arScene = sceneComponent?.getScene() as ARScene | null
            if (arScene) {
                let smoke = arScene.scenario as Smoke
                smoke.smokeLevel = part
            }
        }
    },
    beforeDestroy() {
        if (tl) {
            tl.kill()
            tl = undefined
        }
    }
})
