
import Vue from 'vue'
import { mapState } from 'vuex'
import StartBtn from './StartBtn.vue'
import Progress from './Progress.vue'

interface ThemeParams {
    uiColor: string
}

export default Vue.extend({
    name: 'Preloader',
    components: {
        Progress,
        StartBtn
    },
    props: {
        title: { type: String },
        theme: { type: String }
    },
    computed: {
        ...mapState(['appIsLoaded', 'loadingProgress']),
        themeParams(): ThemeParams {
            switch (this.theme) {
                case 'flood':
                    return { uiColor: 'blue' }

                case 'smoke':
                default:
                    return { uiColor: 'yellow' }
            }
        }
    },
    methods: {
        onStart() {
            this.$emit('start')
        }
    }
})
