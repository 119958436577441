
import Vue from 'vue'

export default Vue.extend({
    name: 'OrientationStub',
    data() {
        return {
            orientation: 'portrait',
            useStub: true
        }
    },
    methods: {
        updateOrientation() {
            this.useStub = window.innerWidth < 980
            this.orientation = (window.innerWidth > window.innerHeight)
                ? 'landscape'
                : 'portrait'
        }
    },
    beforeMount() {
        this.updateOrientation()

        window.addEventListener('orientationchange', this.updateOrientation)
        window.addEventListener('resize', this.updateOrientation)
    },
    beforeDestroy() {
        window.removeEventListener('orientationchange', this.updateOrientation)
        window.removeEventListener('resize', this.updateOrientation)
    }
})
