
import Vue from 'vue'
import { mapMutations } from 'vuex'
import { ARScene } from '@/modules/ARScene'
import { ScenarioNames } from '@/modules/ARScene/scenario/types'
import Preloader from './Preloader/index.vue'
import OrientationStub from './OrientationStub.vue'

let arScene: ARScene | null

export default Vue.extend({
    name: 'Scene',
    components: {
        Preloader,
        OrientationStub
    },
    props: {
        name: { type: String },
        title: { type: String },
        theme: { type: String }
    },
    data() {
        return {
            started: false
        }
    },
    methods: {
        ...mapMutations(['setLoadedState', 'updateLoadingProgress']),
        async letsStart() {
            if (!arScene) {
                return
            }

            await arScene.startScenario()
            this.started = true
            this.$emit('started')
        },
        getScene() {
            return arScene
        }
    },
    async mounted() {
        await this.$nextTick()

        arScene = new ARScene(this.name as ScenarioNames, {
            canvas: this.$refs.canvas as HTMLCanvasElement,
            video: this.$refs.video as HTMLVideoElement
        })

        await arScene.prepareScenario(this.updateLoadingProgress)

        this.setLoadedState(true)
    },
    beforeDestroy() {
        this.setLoadedState(false)

        if (arScene) {
            arScene.destroy()
            arScene = null
        }
    }
})
