import { MeshBasicMaterial } from 'three'
import { OnProgress, OnTick } from '../../core/types'
import Base from '../Base'
import { RESOURCES } from './const'

class Smoke extends Base {
    public smokeLevel = 0

    public async prepare(onProgress?: OnProgress): Promise<void> {
        await super.prepare()
        await this.loadResources(RESOURCES, onProgress)
    }

    public start(): void {
        super.start()

        this.addSphereLayer('overlay', { radius: 110 - 0.5 })
        this.addSphereLayer('mist3', { radius: 90, opacity: 0 })
        this.addSphereLayer('mist2', { radius: 70, opacity: 0 })
        this.addSphereLayer('mist1', { radius: 50, opacity: 0 })

        this.addCamLayer(110)

        this.core.e.on('tick', this.updateSmokeLevel)
    }

    protected updateSmokeLevel: OnTick = () => {
        let totalLevel = this.smokeLevel * 3

        for (let i = 1; i <= 3; i += 1) {
            let mist = this.gameObjects.get(`mist${4 - i}`)
            if (!mist) {
                continue
            }

            let opacity = Math.max(totalLevel - i + 1, 0)
            opacity = Math.min(opacity, 1)

            let material = (mist.mesh.material as MeshBasicMaterial)
            material.opacity = opacity
        }
    }

    public destroy(): void {
        super.destroy()
    }
}

export default Smoke
