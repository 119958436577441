import {
    AdditiveBlending,
    BufferGeometry,
    Float32BufferAttribute,
    Points,
    ShaderMaterial
} from 'three'
import { OnProgress, OnTick } from '../../core/types'
import Base from '../Base'
import {
    RESOURCES,
    SCENE_RANGE,
    PARTICLES_CONFIG
} from './cost'
import { vertexShader, fragmentShader } from './shaders'
import { CollectionConfig, ParticleCollection } from './types'

/* eslint-disable class-methods-use-this */

class Flood extends Base {
    private collection: ParticleCollection[] = []

    public async prepare(onProgress?: OnProgress): Promise<void> {
        await super.prepare()
        await this.loadResources(RESOURCES, onProgress)
    }

    public start(): void {
        super.start()

        this.addSphereLayer('overlay', { radius: SCENE_RANGE + 10 - 0.5, alphaMap: 'overlayMap' })
        this.addSphereLayer('splash', { radius: 90, alphaMap: 'splashMap' })

        this.addCamLayer(SCENE_RANGE + 10)
        this.fillCollection()

        this.core.e.on('tick', this.onTickFlood)
        console.log(this)
    }

    private fillCollection(): void {
        PARTICLES_CONFIG.forEach((CONFIG) => {
            this.fillThisCollection(CONFIG)
        })
    }

    private fillThisCollection(CONFIG: CollectionConfig): void {
        const {
            TYPE, VARIANTS, COUNT, RANGE,
            SIZE_MIN, SIZE_MAX, V_MIN, V_MAX,
            USE_BLENDING, ROTATION_LIMIT
        } = CONFIG

        for (let index = 0; index < VARIANTS; index += 1) {
            let vertices = []
            let sizes = []
            let alpha = []
            let velocity = []
            let rotation = []
            for (let i = 0; i < COUNT; i += 1) {
                let x = RANGE * (2 * Math.random() - 1)
                let y = RANGE * (2 * Math.random() - 1)
                let z = RANGE * (2 * Math.random() - 1)
                vertices.push(x, y, z)

                let ratio = window.devicePixelRatio || 1
                let size = Math.random() * (SIZE_MAX - SIZE_MIN) + SIZE_MIN
                sizes.push(ratio * size)
                velocity.push(Math.random() * (V_MAX - V_MIN) + V_MIN)
                alpha.push(1)
                rotation.push(Math.random() * ROTATION_LIMIT)
            }

            let geometry = new BufferGeometry()
            geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
            geometry.setAttribute('size', new Float32BufferAttribute(sizes, 1))
            geometry.setAttribute('velocity', new Float32BufferAttribute(velocity, 1))
            geometry.setAttribute('alpha', new Float32BufferAttribute(alpha, 1))
            geometry.setAttribute('rotation', new Float32BufferAttribute(rotation, 1))

            let texture = this.res.get(`${TYPE}${index + 1}`)?.texture
            if (texture) {
                texture.flipY = false
            }
            let uniforms = {
                pointTexture: {
                    value: texture
                }
            }

            let material = new ShaderMaterial({
                uniforms,
                vertexShader,
                fragmentShader,
                blending: USE_BLENDING ? AdditiveBlending : undefined,
                depthTest: false,
                transparent: true
            })

            let points = new Points(geometry, material)
            this.collection.push({ config: CONFIG, points })
            this.core.render.scene.add(points)
        }
    }

    public onTickFlood: OnTick = (delta) => {
        this.collection.forEach((particles) => {
            this.updateParticles(particles, delta)
        })
    }

    private updateParticles(particles: ParticleCollection, delta: number): void {
        const { COUNT, RANGE } = particles.config
        let geometry = particles.points.geometry

        let positions = geometry.attributes.position.array as number[]
        let velocities = geometry.attributes.velocity.array as number[]
        let alphas = geometry.attributes.alpha.array as number[]
        geometry.rotateY(0.02 * delta)

        for (let i = 0; i < COUNT; i += 1) {
            let x = positions[i * 3]
            let y = positions[i * 3 + 1]
            let z = positions[i * 3 + 2]

            let alpha = alphas[i]
            let velocity = velocities[i]

            let yT = y + velocity * delta
            let xT = x + Math.sin(yT * 0.1) * 0.05
            let zT = z + Math.cos(yT * 0.1) * 0.05
            if (Flood.IsOutOfRange(yT, RANGE)) {
                alpha -= delta
            } else {
                alpha += delta
                alpha = (alpha < 1) ? alpha : 1
            }

            if (alpha < 0) {
                alpha = 0
                yT = -RANGE
            }

            // set values
            alphas[i] = alpha
            positions[i * 3] = xT
            positions[i * 3 + 1] = yT
            positions[i * 3 + 2] = zT
        }

        geometry.attributes.position.needsUpdate = true
        geometry.attributes.alpha.needsUpdate = true
    }

    static IsOutOfRange(c: number, range: number): boolean {
        return c > range || c < -range
    }
}

export default Flood
