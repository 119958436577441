
import Vue from 'vue'
import Scene from '@/components/Scene.vue'

export default Vue.extend({
    name: 'Flood',
    components: {
        Scene
    },
    metaInfo() {
        return {
            title: 'Опасность глобального потепления'
        }
    }
})
