import wait from '@/utils/wait'
import {
    AmbientLight,
    AxesHelper,
    Object3D,
    PerspectiveCamera,
    Scene,
    WebGLRenderer
} from 'three'
import type ARScene from '../../core'
import { RENDERER_CONFIG } from './const'
import { RenderParams, Size } from './types'

class Render {
    private core: ARScene

    private raf?: number

    public playerSize: Size = { w: 0, h: 0, ratio: 1 }

    public canvas: HTMLCanvasElement

    public video: HTMLVideoElement

    public renderer: WebGLRenderer

    public scene: Scene = new Scene()

    public camera = new PerspectiveCamera(45, 1, 0.1, 1000)

    public pivot = new Object3D()

    constructor(core: ARScene, { canvas, video }: RenderParams) {
        this.core = core

        this.canvas = canvas
        this.video = video

        let rendererConfig = { canvas, ...RENDERER_CONFIG }
        this.renderer = new WebGLRenderer(rendererConfig)
        // this.renderer.outputEncoding = sRGBEncoding
        this.renderer.setPixelRatio(window.devicePixelRatio)

        this.updateRenderSize()
        window.addEventListener('resize', this.updateRenderSize)
        window.addEventListener('orientationchange', this.updateOrientation)

        this.addBasicStuff()
    }

    public updateOrientation = async (): Promise<void> => {
        await wait(500)

        this.updateRenderSize()
        window.scrollTo(0, 1)
    }

    private updateRenderSize = (): void => {
        this.playerSize.w = window.innerWidth
        this.playerSize.h = window.innerHeight
        this.playerSize.ratio = this.playerSize.w / this.playerSize.h

        this.setRenderSize()
    }

    private setRenderSize(): void {
        let { w, h, ratio } = this.playerSize

        if (this.canvas) {
            this.canvas.style.width = `${w}px`
            this.canvas.style.height = `${h}px`
        }

        if (this.renderer) {
            this.renderer.setSize(w, h)
        }

        if (this.camera) {
            this.camera.aspect = ratio
            this.camera.updateProjectionMatrix()
        }
    }

    private addBasicStuff(): void {
        let camera = this.camera
        camera.position.set(0, 0, 0)
        camera.lookAt(0, 0, 100)

        if (this.core.isDebug()) {
            this.pivot.scale.x = -1
        } else {
            this.pivot.scale.z = -1
        }
        this.scene.add(this.pivot)

        let light = new AmbientLight(0xfff, 1)
        this.scene.add(light)

        if (this.core.isDebug()) {
            this.scene.add(new AxesHelper(2))
        }
    }

    public tick(): void {
        if (!this.core.isDebug()) {
            this.pivot.quaternion.copy(this.camera.quaternion)
        }
    }

    public render(): void {
        let renderer = this.renderer
        if (renderer) {
            renderer.render(this.scene, this.camera)
        }
    }

    public destroy(): void {
        if (this.raf) {
            cancelAnimationFrame(this.raf)
            delete this.raf
        }

        window.removeEventListener('resize', this.updateRenderSize)
    }
}

export default Render
