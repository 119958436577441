
import { mapState } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
    name: 'App',
    computed: {
        ...mapState(['appIsLoaded'])
    }
})
