import { ResourceConfig } from '../Base/types'
import { CollectionConfig } from './types'

export const RESOURCES: ResourceConfig[] = [
    {
        name: 'camFake',
        url: '/img/spb.jpg'
    },
    {
        name: 'overlay',
        url: '/img/water_back.jpg'
    },
    {
        name: 'overlayMap',
        url: '/img/water_back_map.jpg'
    },
    {
        name: 'splash',
        url: '/img/water_splash.jpg',
        anim: {
            rotY: 0.04
        }
    },
    {
        name: 'splashMap',
        url: '/img/water_splash_map.jpg'
    },
    {
        name: 'bubble1',
        url: '/img/bubble1.png'
    },
    {
        name: 'bubble2',
        url: '/img/bubble2.png'
    },
    {
        name: 'bubble3',
        url: '/img/bubble3.png'
    },
    {
        name: 'bubble4',
        url: '/img/bubble4.png'
    },
    {
        name: 'trash11',
        url: '/img/trash11.png'
    },
    {
        name: 'trash12',
        url: '/img/trash12.png'
    },
    {
        name: 'trash21',
        url: '/img/trash21.png'
    },
    {
        name: 'trash31',
        url: '/img/trash31.png'
    },
    {
        name: 'trash32',
        url: '/img/trash32.png'
    }
]

export const PARTICLES_CONFIG: CollectionConfig[] = [
    {
        TYPE: 'bubble',
        VARIANTS: 4,
        COUNT: 100,
        RANGE: 200,
        SIZE_MIN: 5,
        SIZE_MAX: 10,
        V_MIN: 5,
        V_MAX: 10,
        ROTATION_LIMIT: 0
    },
    {
        TYPE: 'trash1',
        VARIANTS: 2,
        COUNT: 40,
        RANGE: 200,
        SIZE_MIN: 20,
        SIZE_MAX: 50,
        V_MIN: 3,
        V_MAX: 5,
        ROTATION_LIMIT: Math.PI * 2
    },
    {
        TYPE: 'trash2',
        VARIANTS: 1,
        COUNT: 50,
        RANGE: 200,
        SIZE_MIN: 10,
        SIZE_MAX: 25,
        V_MIN: 2,
        V_MAX: 3,
        ROTATION_LIMIT: Math.PI * 2
    },
    {
        TYPE: 'trash3',
        VARIANTS: 2,
        COUNT: 40,
        RANGE: 200,
        SIZE_MIN: 10,
        SIZE_MAX: 25,
        V_MIN: 2,
        V_MAX: 3,
        ROTATION_LIMIT: Math.PI * 2
    }
]

export const SCENE_RANGE = 100
